@use '~@msaf/core-react/src/styles/0-settings/settings.scss' with (
  // Theme colors
  $color--primary: #103f40,
  $color--primary-active: #165a5b,
  $color--primary-accent-1: #3ab882,
  $color--primary-accent-2: #d39141,
  $color--primary-accent-3: #5ccae8
);

@use '~@msaf/core-react/src/styles/1-mixins/mixins.scss';
@use '~@msaf/core-react/src/styles/2-resets/generic.scss';
@use '~@msaf/core-react/src/styles/3-base/base.scss';
@use '~@msaf/core-react/src/styles/4-layout/layout.scss';
@use '~@msaf/core-react/src/styles/5-components/components.scss';
@use '~@msaf/core-react/src/styles/6-trumps/trumps.scss';

@use './remove-table-cursor';
@use './submission-page.scss';

a {
  color: settings.$color--primary-accent-1;
}

// Ensure heading row max size is 640px for larger viewports
#summaryPDF .l-heading-row.l-heading-row--vert-spacing-small {
  max-width: settings.$size--1500;
}

.c-toolbar__main-menu-btn,
.c-content-page-header__menu-btn,
.c-nav-panel__close-btn {
  cursor: pointer;
}

// Make homepage top banner full width on larger screens
.c-content-banner__body {
  @include mixins.tablet-landscape-up {
    padding: 0;
  }
}

.c-content-block__img {
  @include mixins.tablet-landscape-up {
    padding-left: 60px;
  }
}

// Inline notification about data usage on the homepage and Farm creation form
.c-inline-notification__no-close {
  .c-inline-notification {
    a {
      padding: 15px 0;
      color: initial;
    }
  }

  &.theme-border .c-inline-notification {
    border: 2px solid settings.$color--primary-accent-1;
  }

  &.in-form {
    max-width: 512px;

    a {
      font-weight: normal;
    }
  }
}

// Improve navigation to fragment links
.help-markdown {
  .c-divider {
    margin-bottom: 0;
  }

  .help-centre-anchor {
    margin-top: 0;
    padding-top: 48px;
  }
}

.has-subheading {
  margin-bottom: 5px;
}
.is-subheading {
  font-size: small;
  margin-bottom: 32px;
  display: inline-block;
}

.l-content-container .c-inline-notification {
  max-width: 640px;
}

.wide-notification .c-inline-notification {
  max-width: initial;
}


.remove-notification-padding .c-inline-notification {
  margin-top: 0;
}

@media (min-width: 600px) {
  .c-wide-map {
    max-width: 80%;

    .c-leaflet-map {
      height: 60vh;
    }
  }
}
