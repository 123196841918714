// Overrides to assist with PDF styling

#summaryPDF.rendering {
    width: 768px;

    .purchase-wrapper {
        margin-top: 20px;
    }

    .c-read-only-field--default,
    .c-card,
    .l-form-field-row {
        max-width: none;
    }

    .c-card {
        box-shadow: none;
        border-left: 2px solid lightgrey;
        margin: 0;
        border-radius: 0;

        &.c-card__first {
            padding-top: 15px;
        }

        .c-card__content {
            padding: 0 24px;
        }
    }

    // Hide map, doesn't render in PDF
    .c-leaflet-map {
        display: none;
    }
}
